import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';

type PropTypes = {
  type?: 'max' | 'vertical' | 'header';
  marginTop?: boolean;
  noMargin?: boolean;
  css?: CSS;
};

export const Divider = ({ type, marginTop, noMargin, css }: PropTypes) => {
  return (
    <BaseDivider
      css={css}
      type={type}
      {...(marginTop && { marginTop })}
      {...(noMargin && { noMargin })}
    />
  );
};

const BaseDivider = styled('div', {
  h: 0.25,
  w: 16,
  mt: 0,
  mb: 6,
  backgroundColor: '$borderPrimary',
  '@mediaMaxSmall': {
    mt: 6,
    mb: 3,
  },
  variants: {
    type: {
      max: {
        w: 'auto',
      },
      vertical: {
        w: 0.25,
        h: '100%',
        my: 0,
        mx: 6,
      },
      header: {
        opacity: 0.5,
        h: 12,
        w: 0.25,
        my: 0,
        mx: 6,
      },
    },
    marginTop: {
      true: {
        mt: 6,
      },
    },
    noMargin: {
      true: {
        m: 0,
      },
    },
  },
  compoundVariants: [
    {
      marginTop: true,
      noMargin: true,
      css: {
        mx: 0,
        my: 6,
      },
    },
  ],
});

export default Divider;
