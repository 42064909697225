import KexFallbackBlock from 'Kex/KexFallbackBlock';
import React, { useRef } from 'react';

import loadable from '@loadable/component';

import { IS_SERVER_CONTEXT } from '../Shared/Configs/EnvConfig';
import useOnScreen from '../Shared/Hooks/useOnScreen';
import ContentAreaItem from '../Models/App/ContentAreaItem.interface';

//TODO refactor with correct blocktypes
type PropType = {
  blockType: BlockTypeKey;
  isVisible: boolean;
  isNested: boolean;
  nextBlockProps?: ContentAreaItem;
  content: any;
  children: React.ReactNode;
};

const loadableComponents = {
  StandardBlock: loadable(
    () => import('Organisms/Blocks/StandardBlock/StandardBlock')
  ),
  ProductListingBlock: loadable(
    () => import('Organisms/Blocks/ProductListingBlock')
  ),
  ImageLinkListingBlock: loadable(
    () => import('Organisms/Blocks/ImageLinkListingBlock')
  ),
  ImageLinkBlock: loadable(() => import('Organisms/Blocks/ImageLinkBlock')),
  IconBlock: loadable(() => import('Organisms/Blocks/IconBlock')),
  LinkListBlock: loadable(() => import('Organisms/Blocks/LinkListBlock')),
  DocumentListBlock: loadable(
    () => import('Organisms/Blocks/DocumentListBlock')
  ),
  VideoBlock: loadable(() => import('Organisms/Blocks/VideoBlock')),
  SectionBlock: loadable(() => import('Organisms/Blocks/SectionBlock')),
  TextBlock: loadable(() => import('Organisms/Blocks/TextBlock')),
  FeaturesArrangementBlock: loadable(
    () => import('Organisms/Blocks/FeatureArrangemenetBlock')
  ),
  HeroBlock: loadable(() => import('Organisms/Blocks/HeroBlock')),
  CoWorkersBlock: loadable(
    () => import('Organisms/Blocks/CoworkersBlock/CoworkersBlock')
  ),
  MapsBlock: loadable(() => import('Organisms/Blocks/MapsBlock')),
};

export type BlockTypeKey = keyof typeof loadableComponents;

export const isBlockKeyType = (x?: string): x is BlockTypeKey =>
  !!x && Object.keys(loadableComponents).includes(x);

function KexLoadableBlock(props: PropType) {
  const ref = useRef<HTMLSpanElement>(null);
  const onScreen = useOnScreen({ ref });
  const { blockType, isVisible } = props;
  const LoadableComponent = loadableComponents[blockType];

  return isVisible || onScreen || IS_SERVER_CONTEXT ? (
    <LoadableComponent
      fallback={<KexFallbackBlock />}
      content={props.content}
    />
  ) : (
    <span ref={ref} />
  );
}

export default KexLoadableBlock;
