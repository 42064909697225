import { styled } from 'stitches.config';

type PropType = {
  loaded: number;
};

function ProgressBar({ loaded }: PropType) {
  return (
    <Bar>
      <Loaded css={{ width: loaded ? `${loaded}%` : '0' }} />
    </Bar>
  );
}

const Bar = styled('div', {
  position: 'relative',
  display: 'flex',
  mt: 4,
  mb: 6,
  w: 64,
  h: 0.5,
  backgroundColor: '$JE68GreenBackground',
  $mediaMinSmall: {
    w: 90,
  },
});

const Loaded = styled('div', {
  backgroundColor: '$JE68GreenPrimary',
  top: 0,
  left: 0,
  w: 0,
  transition: '$1000 cubic-bezier(0.25, 0.46, 0.45, 0.94)',
});

export default ProgressBar;
