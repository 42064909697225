import ContentAreaItem from 'Models/App/ContentAreaItem.interface';
import React from 'react';

import KexLoadableBlock, { isBlockKeyType } from '../../Kex/KexLoadableBlock';

type PropType = {
  childItems: ContentAreaItem[];
  isVisible?: boolean;
  nestedContentArea?: boolean;
};

function ContentArea({
  childItems = [],
  isVisible = false,
  nestedContentArea = false,
}: PropType) {
  const renderTopComponents = (
    component: ContentAreaItem,
    parentI: string,
    nestedBlock: boolean,
    nextComponent?: ContentAreaItem
  ) => {
    if (!component) {
      return <React.Fragment key={parentI} />;
    }

    const blockKeyType = component.blockType;

    if (!isBlockKeyType(blockKeyType)) {
      console.warn(`Unknown block type ${blockKeyType}`);
      return <React.Fragment key={parentI} />;
    }

    return (
      <KexLoadableBlock
        {...component}
        blockType={blockKeyType}
        isNested={nestedBlock}
        nextBlockProps={nextComponent}
        key={component.blockId ? component.blockId + parentI : parentI}
        isVisible={!!isVisible}
      >
        {component.childItems?.map((subComponent, i, arr) =>
          renderTopComponents(subComponent, parentI + '_' + i, true, arr[i + 1])
        )}
      </KexLoadableBlock>
    );
  };
  return (
    <>
      {childItems.map((component, i, arr) =>
        renderTopComponents(component, '' + i, nestedContentArea, arr[i + 1])
      )}
    </>
  );
}

export default ContentArea;
