import IconBaseType from 'Atoms/Icons/IconBaseType';
import { styled } from 'stitches.config';

type LoadingCircleType = Omit<IconBaseType, 'size'> & {
  isLoading?: boolean;
  size?: 's' | 'l' | 'xl';
};

function LoadingCircle({ size, isLoading }: LoadingCircleType) {
  return (
    <LoadingSvgStyles
      size={size}
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      isLoading={isLoading}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="4">
          <LoadingCircleStyles strokeOpacity="1" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </LoadingSvgStyles>
  );
}

const LoadingSvgStyles = styled('svg', {
  stroke: '$JE68GreenPrimary',
  overflow: 'visible',
  variants: {
    isLoading: {
      true: {
        display: 'initial',
      },
      false: {
        display: 'none',
      },
    },
    size: {
      s: {
        w: 6,
        h: 6,
      },
      l: {
        h: 12,
        w: 12,
      },
      xl: {
        h: 16,
        w: 16,
      },
    },
  },
  defaultVariants: {
    size: 's',
  },
});

const LoadingCircleStyles = styled('circle', {
  stroke: '$JE68GreenBackground',
});

export default LoadingCircle;
