import NewsCardModel from 'Models/NewsCard/NewsCardModel.interface';
import NewsArchivePageModel from 'Models/Pages/NewsArchivePage/NewsArchivePageModel.interface';
import LoadMoreContainer from 'Molecules/LoadMoreContainer/LoadMoreContainer';
import ContentArea from 'Organisms/ContentArea/ContentArea';
import HeroModule from 'Organisms/Hero/HeroModule';
import NewsCard from 'Organisms/NewsCard/NewsCard';
import { useState } from 'react';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import useMedia from 'Shared/Hooks/useMedia';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

function NewsArchivePage() {
  const {
    contentArea,
    inEditMode,
    heroHeader,
    heroImage,
    heroText,
    newsCards,
  } = useCurrentPage<NewsArchivePageModel>();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  const {
    newsLabels: { newsLable, newsMobileCounter },
    searchLabels: { loadMore, counter },
  } = useTranslationData();

  const [newsCardsToShow, setNewsCardsToShow] = useState(
    newsCards.slice(0, 10)
  );
  const totalNewsCards = newsCards.length;
  const counterText = !!newsCards.length
    ? counter
        .replace('{0}', newsCardsToShow.length.toString())
        .replace('{1}', newsCards.length.toString())
    : '';
  const loaded = (newsCardsToShow.length / totalNewsCards) * 100;

  const mobileCounterText =
    !isDesktop && !!newsCards.length
      ? newsMobileCounter
          .replace('{0}', newsCardsToShow.length.toString())
          .replace('{1}', newsCards.length.toString()) +
        ' ' +
        newsLable.toLowerCase()
      : '';

  const increaseNumberOfCardsShown = () => {
    setNewsCardsToShow(newsCards.slice(0, newsCardsToShow.length + 10));
  };

  return (
    <>
      {heroImage && (
        <HeroModule
          header={heroHeader}
          heroText={heroText}
          inEditMode={inEditMode}
          image={heroImage}
        />
      )}
      <NewsCardsContainerContainer>
        {!!mobileCounterText.length && (
          <MobileCounterWrapper>{mobileCounterText}</MobileCounterWrapper>
        )}
        <NewsCardWrapper>
          {newsCardsToShow.map((card: NewsCardModel, index) => (
            <NewsCard
              key={card.href}
              item={card}
              isLastItem={index === newsCardsToShow.length - 1}
            />
          ))}
        </NewsCardWrapper>
        <LoadMoreContainer
          isLoading={loaded < 100}
          loaded={loaded}
          counterText={counterText}
          loadMore={loadMore}
          disabled={newsCardsToShow.length >= newsCards.length}
          onClick={increaseNumberOfCardsShown}
        />
        <ContentArea childItems={contentArea} />
      </NewsCardsContainerContainer>
    </>
  );
}

const NewsCardsContainerContainer = styled('div', {
  w: '100%',
  maxW: '$contentMaxWidth',
  mx: 'auto',
  px: 2,
});

const MobileCounterWrapper = styled('div', {
  h: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fs: 5,
  ls: '$ls06',
  '@mediaMinSmall': {
    fs: 6,
  },
});

const NewsCardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$backgroundPrimary',
  wh: '100%',
  maxW: '$contentResultWidth',
  mb: 16,
  mx: 'auto',
  p: 8,
  '@mediaMinLarge': {
    mt: 16,
  },
});

export default NewsArchivePage;
