import { H1 } from 'Atoms/Typography/Headings/Heading';
import ImageModel from 'Models/Assets/ImageModel.interface';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';

type HeroModuleType = {
  image: ImageModel;
  inEditMode: boolean;
  header?: string;
  heroText?: string;
};

function HeroModule({ header, heroText, inEditMode, image }: HeroModuleType) {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);

  return (
    <ImageContainer
      css={{
        backgroundImage: isMobile
          ? `url(${image.mobileSrc})`
          : `url(${image.src})`,
      }}
    >
      <ContentContainer>
        <HeroWrapper>
          <TextContainer>
            <H1
              {...applyEditModeAttr(inEditMode && 'HeroHeader')}
              color={'tertiary'}
              css={{ my: 0, mb: 4 }}
            >
              {header}
            </H1>
            <TextWrapper>
              <Paragraph {...applyEditModeAttr(inEditMode && 'HeroText')}>
                {heroText}
              </Paragraph>
            </TextWrapper>
          </TextContainer>
        </HeroWrapper>
      </ContentContainer>
    </ImageContainer>
  );
}

const ImageContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  color: '$heroTextPrimary',
  mb: 3,
  h: '$heroImageMobileHeight',
  '@mediaMinLarge': {
    h: '$heroImageDesktopHeight',
  },
  '@mediaMinMassive': {
    h: '$heroImageWideScreenHeight',
  },
  '&:before': {
    position: 'absolute',
    content: '""',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '$backgroundPrimary',
    opacity: 0.4,
  },
});

const HeroWrapper = styled('div', {
  display: 'flex',
  height: '100%',
  alignItems: 'flex-end',
});

const TextWrapper = styled('div', {
  position: 'relative',
  overflowY: 'hidden',
  '& > p': {
    m: 0,
  },
});

const TextContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  w: '100%',
  justifyContent: 'end',
  mt: 11,
  mb: 20,
  maxW: '$maxWidthMobile',
  '@mediaMinLarge': {
    mx: 0,
    mb: 30,
    maxW: 187,
  },
});

export default HeroModule;
