import { Divider } from 'Atoms/Divider/Divider';
import { H2 } from 'Atoms/Typography/Headings/Heading';
import KexLink from 'Kex/KexLink';
import NewsCardModel from 'Models/NewsCard/NewsCardModel.interface';
import { styled } from 'stitches.config';

type PropType = {
  item: NewsCardModel;
  isLastItem?: boolean;
};

function NewsCard({
  item: { header, href, tags, preamble, publishDate },
  isLastItem,
}: PropType) {
  return (
    <div>
      <TopDiv>
        <DateSpan>{publishDate}</DateSpan>
        <TagsWrapper>
          {tags.map((tag, index) => (
            <p key={index}>
              {tag}
              {index !== tags.length - 1 && ','}
              &nbsp;
            </p>
          ))}
        </TagsWrapper>
      </TopDiv>
      <KexLink href={href} underlined={false}>
        <H2 noMargin={true}>{header}</H2>
        <Preamble>
          {preamble.length >= 190
            ? preamble.substring(0, 190).slice(0, -3) + '...'
            : preamble}
        </Preamble>
      </KexLink>
      {!isLastItem && <Divider type="max" marginTop noMargin />}
    </div>
  );
}

const TagsWrapper = styled('div', {
  display: 'flex',
});

const TopDiv = styled('div', {
  display: 'flex',
  fs: 7,
  lineHeight: '$lh15',
  ls: '$ls08',
  justifyContent: 'space-between',
  mb: 2,
  color: '$newsCardTextPrimary',
});

const DateSpan = styled('p', {
  opacity: 0.7,
});

const Preamble = styled('p', {
  lineHeight: '$ls175',
  ls: '$ls08',
  pt: 2,
});

export default NewsCard;
