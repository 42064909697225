import Button from 'Atoms/Buttons/Button';
import ProgressBar from 'Atoms/Loaders/ProgressBar';
import { styled } from 'stitches.config';

type PropType = {
  loaded: number;
  counterText: string;
  loadMore: string;
  disabled?: boolean;
  onClick?: (x?: () => void) => void;
  isLoading: boolean;
};

const LoadMoreContainer = ({
  loaded,
  counterText,
  loadMore,
  disabled = false,
  onClick,
  isLoading,
}: PropType) => {
  return (
    <LoadContainer>
      <Counter css={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        {counterText}
      </Counter>
      <ProgressBar loaded={loaded} />
      {loaded !== 100 && (
        <Button
          disabled={disabled}
          type={'tertiary'}
          onClick={() => {
            onClick && onClick();
          }}
          size={'s'}
          css={{ width: '100%' }}
          isLoading={isLoading}
        >
          {loadMore}
        </Button>
      )}
    </LoadContainer>
  );
};

export default LoadMoreContainer;

const LoadContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pb: 16,
  mx: 'auto',
  maxW: 64,
});

const Counter = styled('span', {
  fs: 6,
  fontFamily: '$fontSecondary400',
  color: '$textPrimary',
  lineHeight: '$lh133',
});
